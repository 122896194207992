/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import ContactUs from "../../common/ContactUs";
import Customers from "../../common/Customers";
import Footer from "../../common/Footer";
import Page from "../../common/Page";
import Meta from "../../common/Meta";
import Section from "../../common/Section";
import ServicesHero from "../../services/ServicesHero";
import {H1} from "../../common/typography/Headings";

const ServicesEn = () => (
    <Page lang="en" title="Services" path="/en/services/">
        <Meta name="description">
            Atilika help startups and enterprises solve difficult problems.
        </Meta>
        <ServicesHero>
            <H1>Services</H1>
            <p>We help startups and enterprises solve difficult problems</p>
        </ServicesHero>

        <Section title="Search">
            <p>
                We are search experts and can help improve any aspect of your search system,
                including customizations to large-scale deployments of Lucene, Solr, and
                elasticsearch.
            </p>
        </Section>

        <Section title="NLP" grey>
            <p>
                We have extensive experience in text processing East Asian and Western languages.
                Expertise includes morphological analysis, key phrase and concept extraction, named
                entity extraction, and sentiment analysis.
            </p>
        </Section>

        <Section title="Machine learning &amp; AI">
            <p>
                Using the latest advances in machine and deep learning, we craft solutions that
                leverage big data to solve complex problems.
            </p>
        </Section>

        <Section title="Application prototypes" grey>
            <p>
                We have expertise in all areas of the stack and work with our clients to quickly
                develop application prototypes for new features or even entire products.
            </p>
        </Section>

        <Customers title="Our customers" />

        {/*<Testimonials title="Testimonials">*/}
        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Ut tincidunt metus eget velit convallis, ut placerat sem aliquet.*/}
        {/*Aliquam hendrerit.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Ut tincidunt metus eget velit convallis, ut placerat sem aliquet.*/}
        {/*Aliquam hendrerit.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Phasellus in turpis feugiat neque elementum dictum vitae vel sem.*/}
        {/*In hac habitasse platea dictumst. Donec vulputate.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*In et dui quis elit malesuada ultrices.*/}
        {/*Etiam vitae urna at dolor mollis maximus.*/}
        {/*Quisque risus mauris, elementum id euismod a, fermentum.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme Inc, Pieter-Jan">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Suspendisse eget augue magna.*/}
        {/*Aenean urna magna, congue id nisi at, euismod blandit est.*/}
        {/*Morbi sit amet hendrerit massa, sed fringilla odio.*/}
        {/*Sed vel ornare purus.*/}
        {/*In lobortis, metus sit amet faucibus consequat,*/}
        {/*urna turpis facilisis nunc, sit amet finibus purus mi.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}
        {/*</Testimonials>*/}

        <ContactUs title="Let’s get to work">
            <p>Contact us about your project today for a free consultation.</p>
        </ContactUs>

        <Footer lang="en" />
    </Page>
);

export default memo(ServicesEn);
