/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import clsx from "clsx";
import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import UI from "./UI";
import {H2} from "./typography/Headings";
import {Lead} from "./typography/Text";
import Container from "./Container";

const useStyles = createUseStyles({
    root: {
        padding: "3.5rem 0 5rem",
        [UI.media.only("xs")]: {
            padding: "1.5rem 0 2rem"
        }
    },
    grey: {
        backgroundColor: UI.colors.offWhite.toCSS()
    }
});

interface SectionProps {
    title: string;
    grey?: boolean;
    children?: ReactNode;
    textSize?: "small" | "large";
}

function Section(props: SectionProps) {
    const classes = useStyles();
    return (
        <section className={clsx(classes.root, {[classes.grey]: props.grey})}>
            <Container>
                <H2>{props.title}</H2>
                {props.children ? (
                    <Lead size={props.textSize || "small"}>{props.children}</Lead>
                ) : null}
            </Container>
        </section>
    );
}

export default memo(Section);
