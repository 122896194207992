/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import ClusterIllustration from "../assets/images/illustrations/services-cluster.svg";
import Hero from "../common/hero/Hero";
import HeroContent from "../common/hero/HeroContent";
import HeroMask from "../common/hero/HeroMask";
import HeroIllustration from "../common/hero/HeroIllustration";
import HeroBackground from "../common/hero/HeroBackground";
import {createUseStyles} from "react-jss";
import {StaticImage} from "gatsby-plugin-image";

interface ServicesHeroProps {
    children: ReactNode;
}

const useStyles = createUseStyles({
    background: {
        objectPosition: "center top",
        objectFit: "cover",
        width: "100%",
        height: "100%"
    }
});

function ServicesHero(props: ServicesHeroProps) {
    const classes = useStyles();

    return (
        <Hero>
            <HeroBackground opacity={0.66}>
                <StaticImage
                    className={classes.background}
                    src="../assets/raw-photos/subway.jpg"
                    alt=""
                    layout="constrained"
                    width={2500}
                />
            </HeroBackground>

            <HeroMask />
            <HeroIllustration
                sizeXs="400px auto"
                image={ClusterIllustration}
                position="center 140px"
            />
            <HeroContent>{props.children}</HeroContent>
        </Hero>
    );
}

export default memo(ServicesHero);
