/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import {createUseStyles} from "react-jss";
import UI from "../UI";

interface HeroIllustrationProps {
    image: string;
    position?: string;
    size?: string;
    sizeXs?: string;
}

const useStyles = createUseStyles({
    root: {
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
        backgroundImage: ({image}: HeroIllustrationProps) => `url("${image}")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: ({position}: HeroIllustrationProps) => position,
        zIndex: 3,
        backgroundSize: ({size}: HeroIllustrationProps) => size,
        [UI.media.only("xs")]: {
            backgroundSize: ({sizeXs}: HeroIllustrationProps) => sizeXs
        }
    }
});

function HeroIllustration(props: HeroIllustrationProps) {
    const classes = useStyles(props);
    return <div className={classes.root} />;
}

export default memo(HeroIllustration);
